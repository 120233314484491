<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
  </div>
</template>

<script>
import { getReportPage } from '@/api/sf/business'

export default {
  components: {
  },
  data () {
    return {
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyword: ''
      },
      tableData: [],
      columnManagedata: [
        {
          title: '商家名称',
          key: 'name'
        },
        { title: '联系电话', key: 'tel' },
        {
          title: '联系地址',
          key: 'address',
          render: (h, params) => {
            return h('div', [
              h(
                'label',
                params.row.provinceName + params.row.cityName + params.row.regionName + params.row.address
              )
            ])
          }
        },
        { title: '创建时间', key: 'statusName' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$refs.contractDetail.showModal(params.row.id)
                    }
                  }
                },
                '详情'
              ),
              params.row.status === 5 ? h(
                'a',
                {
                  style: { marginRight: '5px', color: 'red' },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        title: '操作提示',
                        content: '是否作废当前储值合同？',
                        onOk: () => {

                        }
                      })
                    }
                  }
                },
                '作废'
              ) : null
            ])
          }
        }
      ]

    }
  },
  created () {
    this.initPageData()
  },
  methods: {

    initPageData () {
      getReportPage(this.query).then((response) => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    }
  },
  watch: {
  },
  computed: {
  }
}
</script>
